/* eslint-disable jsx-a11y/label-has-for */
/**
 * CallToAction
 */

import React from 'react';
import qs from 'qs';
import get from 'lodash/get';
import { useStaticQuery, graphql } from 'gatsby';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import classnames from 'classnames';
import isValidEmail from '@utils/isValidEmail';
import axios from 'axios';
import { ContentParser } from '@components';

const CallToAction = () => {
  // Get labels, message etc. from wordpress CTA options page
  const options = get(useStaticQuery(graphql`
  query CTAOptions {
    wordpressAcfOptions {
      options {
        cta_button_shortcut
        cta_button_title
        cta_error_messages {
          name
          email
          name_email
        }
        cta_labels {
          name
          email
        }
        cta_success_message      
      }
    }
  }
`), 'wordpressAcfOptions.options', {});

  const onSubmit = ({ name: cta_name, email: cta_email }) => axios.post('https://wp.samhaeng.com/wp-json/contact-form-7/v1/contact-forms/484/feedback', qs.stringify({ 
    cta_name,
    cta_email
  })).then(response => {
    if (response.data.status === 'validation_failed') {
      throw response;
    }
    
    return true;
  }).catch(() => ({
    [FORM_ERROR]: options.cta_error_messages.name_email,
  }));

  const validate = values => {
    const errors = {};

    if (!values.email && !values.name) {
      errors[FORM_ERROR] = options.cta_error_messages.name_email;
      return errors;
    }

    if (!values.name) {
      errors[FORM_ERROR] = options.cta_error_messages.name;
      return errors;
    }

    if (!isValidEmail(values.email)) {
      errors[FORM_ERROR] = options.cta_error_messages.email;
      return errors;
    }

    return errors;
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ error, handleSubmit, submitting, submitError, submitFailed, submitSucceeded }) => (
        <div 
          className={
            classnames(
              'page__block--form',
              {'has-success': submitSucceeded},
              {'has-error': submitFailed}
            )
          }
        >
          <form noValidate method="post" onSubmit={handleSubmit}>
            <div className="form__input form__input">
              <Field
                component="input"
                id="name"
                name="name"
                type="text"
              />
              <label htmlFor="name">
                {options.cta_labels.name}
              </label>
            </div>
            <div className="form__input form__input">
              <Field
                component="input" 
                id="email"
                name="email"
                type="email"
              />
              <label htmlFor="email">
                {options.cta_labels.email}
              </label>
            </div>
            <div className="error-area">
              {submitFailed && (submitError || error)}
            </div>
            <button className="form__button--submit" type="submit" disabled={submitting}>
              <div className="button__shortcut">{options.cta_button_shortcut}</div>
              <div className="button__label">{options.cta_button_title}</div>
            </button>
            <ContentParser element="div" content={options.cta_success_message} className="success-area" />
          </form>
        </div>
      )}
    />
  );
}

export default CallToAction;
